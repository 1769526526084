body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  padding: 16px;
}

.input {
  width: 13%;
  height: 40px;
  padding: 0;
  font-size: 3vh;
  text-align: center;
  margin-right: 1%;
  text-transform: lowercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}
.input_m {
  width: 13%;
  height: 5vh;
  padding: 0;
  font-size: 3vh;
  text-align: center;
  margin-right: 1%;
  text-transform: lowercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}
.input:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}
code {
  width:"800px";
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
